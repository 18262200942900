import { useState, useCallback } from "react";
import { createModel } from "hox";

function useResetTimer() {
  const [timer, setTimer] = useState(0);
  const reset = useCallback(() => setTimer(0), []);
  
  return {
    timer, setTimer, reset
  };
}

export default createModel(useResetTimer);