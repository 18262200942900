import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import NavigationIcon from "@material-ui/icons/Navigation";
import * as moment from "moment";
import * as R from "ramda";
import React from "react";
import {
  determineBadView,
  determineSlipping,
  determineStrongWind,
  toRiskIcon,
  weatherCodeToName,
  winSpeedToColor,
} from "./utils";

const legendDisplayInfo = [
  { color: "#1627ba", text: "弱風" },
  { color: "#eec414", text: "強風: 運転注意" },
  { color: "#be10df", text: "強風: 運転警戒" },
];

export function Weather({ points, data, setVisible }) {
  if (!data || !points) return null;

  const selectedData = R.find((f) => f.pointno === points[0].pointNo, data);

  const now = moment().utc();
  now.set("hour", now.hour() + 1 - (now.hour() % 1));
  const nexts = R.map((x) => now.clone().add(x, "hour"), [1, 2, 3, 4]);
  const times = [now, ...nexts];
  const localTimes = times.map((t) => t.clone().local());
  const forecast = selectedData;
  if (!forecast) return null;
  const records = R.map(
    (t) =>
      R.find(
        (f) =>
          f.forecast_date.hour === t.hour() &&
          f.forecast_date.day === t.date() &&
          f.forecast_date.month === t.month() + 1 &&
          f.forecast_date.year === t.year(),
        forecast.forecast_data
      ),
    times
  );
  if (records.filter((r) => r).length !== 5) {
    console.log("Weather: not enough records", records.filter((r) => r).length);
    return null;
  }
  const weatherIcons = records.map((r, i) => (
    <TableCell key={i}>
      <Image
        src={`${process.env.PUBLIC_URL}/weather_icons/${weatherCodeToName(
          r.WX
        )}.png`}
        alt=""
        boxSize={["20px", "40px"]}
      />
    </TableCell>
  ));
  const temps = records.map((r, i) => (
    <TableCell key={i}>
      <Text>{Math.round(r.TEMP / 10)}°C</Text>
    </TableCell>
  ));
  const winds = records.map((r, i) => (
    <TableCell key={i}>
      {r.WNDSPD === 0 ? (
        <Text fontWeight="medium">無風</Text>
      ) : (
        <NavigationIcon
          style={{
            transform: `rotate(${(r.WNDDIR / 16) * 360 + 180}deg`,
            color: winSpeedToColor(r.WNDSPD),
          }}
        />
      )}
    </TableCell>
  ));

  return (
    <Box maxH={["80vh", "unset"]}>
      <Box
        display="flex"
        width={"100%"}
        justifyContent="center"
        px="12px"
        position="relative"
      >
        <Text fontSize="2xl" maxW="80%" noOfLines={1}>
          {points[0].pointName}
        </Text>
        <Box position="absolute" top={-2.5} right={0}>
          <IconButton onClick={() => setVisible(false)}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {R.map(
                (t) => (
                  <TableCell key={t.hour()}>{t.hour()}時</TableCell>
                ),
                localTimes
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {records.some((r) =>
              determineBadView(r) !== null ? true : false
            ) ? (
              <TableRow key="badview">
                <TableCell key="name">視界不良</TableCell>
                {records.map((r, i) => (
                  <TableCell key={i}>
                    <Image
                      src={toRiskIcon(determineBadView(r))}
                      alt=""
                      boxSize={["20px", "40px"]}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ) : null}
            {records.some((r) =>
              determineStrongWind(r) !== null ? true : false
            ) ? (
              <TableRow key="strongwind">
                <TableCell key="name">横風強風</TableCell>
                {records.map((r, i) => (
                  <TableCell key={i}>
                    <Image
                      src={toRiskIcon(determineStrongWind(r))}
                      alt=""
                      boxSize={["20px", "40px"]}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ) : null}
            {records.some((r) =>
              determineSlipping(r) !== null ? true : false
            ) ? (
              <TableRow key="slipping">
                <TableCell key="name">スリップ</TableCell>
                {records.map((r, i) => (
                  <TableCell key={i}>
                    <Image
                      src={toRiskIcon(determineSlipping(r))}
                      alt=""
                      boxSize={["20px", "40px"]}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ) : null}
            <TableRow key="weather">
              <TableCell key="name">天気</TableCell>
              {weatherIcons}
            </TableRow>
            <TableRow key="temp">
              <TableCell key="name">気温</TableCell>
              {temps}
            </TableRow>
            <TableRow key="wind">
              <TableCell key="name">風</TableCell>
              {winds}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Flex alignItems="center" justifyContent={"center"}>
        {legendDisplayInfo.map((i) => {
          return (
            <Flex mx={3} key={i.text}>
              <NavigationIcon
                style={{
                  color: i.color,
                  transform: `rotate(-90deg)`,
                }}
              />
              <Text fontWeight={"black"}>{i.text}</Text>
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
}
