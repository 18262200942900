import { useBreakpointValue } from "@chakra-ui/react";
import { useRequest } from "ahooks";
import jaconv from "jaconv";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Circle as CircleStyle, Fill, Style, Text } from "ol/style";
import { useEffect, useState } from "react";
import { changeBaseXyToPoint } from "./utils";

const calculateRotation = (ic) => {
  const deltaY = ic.endY - ic.startY;
  const deltaX = ic.endX - ic.startX;
  if (Math.abs(deltaY) > Math.abs(deltaX)) {
    return Math.PI / 2;
  } else {
    return 0;
  }
};

export function useSAPALayers() {
  const { data } = useRequest("/api/map/sapa", {});
  const isSmartPhone = useBreakpointValue({ base: true, md: false });

  const [layers] = useState(() => [
    new VectorLayer({
      source: new VectorSource(),
    }),
    new VectorLayer({
      source: new VectorSource(),
      maxResolution: isSmartPhone ? 1.7 : 1.35,
    }),
  ]);

  useEffect(() => {
    if (!data) return;
    let features = [];
    let mainFeatures = [];
    data.forEach((ic) => {
      const rotation = calculateRotation(ic);
      const leftOrTop =
        rotation === 0 ? ic.startX < ic.downPointX : ic.startY < ic.downPointY;
      const name = jaconv.toZenAscii(
        ic.sapaName.replace("SASA", "SA").replace("PAPA", "PA")
      );
      const feature = new Feature(
        new Point(
          changeBaseXyToPoint(
            [
              {
                startX:
                  rotation !== 0 ? ic.endX : leftOrTop ? ic.endX : ic.startX,
                startY:
                  rotation === 0 ? ic.endY : leftOrTop ? ic.endY : ic.startY,
              },
            ],
            "point"
          )
        )
      );
      feature.setStyle(
        new Style({
          text: new Text({
            font: isSmartPhone
              ? `600 9px/1.1 'Kosugi Maru'`
              : `600 14px/0.95 'Kosugi Maru'`,
            text:
              rotation !== 0
                ? name.replace("ー", "｜").split("").join("\n")
                : name,
            fill: new Fill({
              color: "green",
            }),
            textAlign:
              rotation === 0 ? (leftOrTop ? "right" : "left") : "right",
            textBaseline:
              rotation === 0 ? "bottom" : leftOrTop ? "bottom" : "top",
            offsetX: rotation === 0 ? 0 : isSmartPhone ? -2 : -8,
            offsetY: rotation || (isSmartPhone ? 0 : -4),
          }),
        })
      );
      [
        [ic.upPointX, ic.upPointY],
        [ic.downPointX, ic.downPointY],
      ].forEach((point) => {
        const circleFeature = new Feature(
          new Point(
            changeBaseXyToPoint(
              [
                {
                  startX: point[0],
                  startY: point[1],
                },
              ],
              "point"
            )
          )
        );
        circleFeature.setStyle(
          new Style({
            image: new CircleStyle({
              radius: 2,
              fill: new Fill({ color: "#0D7514" }),
            }),
          })
        );
        mainFeatures.push(circleFeature);
      });
      features.push(feature);
    });
    layers[0].setSource(new VectorSource({ features: mainFeatures }));
    layers[1].setSource(new VectorSource({ features }));
  }, [data, layers, isSmartPhone]);

  return layers;
}
