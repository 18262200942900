export const MONITORING_ENDPOINT = "https://dwinfogen.wni.co.jp/api/data";
export const smartPhoneCoefficient = 2.5;
export const CM_CONSTANT = {
  MAP: {
    INIT_FLAG: 1, // init flag
    URL: "/pcsite/map/", // URL
    CGI: {
      WEATHER: "/cgi/weather_forecast.fcgi", // 天気予測取得
      PROVE: "/cgi/carprove.cgi?d=pc&area=",
    },
    LINK: {
      HONSHI: "http://tb.wni.com/rdhonshi/", // リンク先
    },
    HTML: {
      MP_MAP: { ID: "#cm_contents", URL: "/pcsite/map/load/MP_Map.html" },
      INFO_AREA_SELECT: {
        ID: "#mp_area_select",
        URL: "/pcsite/map/load/MP_InfoArea.html",
      },
      INFO_DATA_BOX: {
        ID: "#mp_data_box",
        URL: "/pcsite/map/load/MP_InfoDatabox.html",
      },

      MAP_KEY_BOX: {
        ID: "#mp_map_key_box",
        URL: "/pcsite/map/load/MP_MapKeybox.html",
      },
      MAP_LAYER_BOX: {
        ID: "#mp_map_layer_box",
        URL: "/pcsite/map/load/MP_MapLayerbox.html",
      },
      MAP_MODE_BOX: {
        ID: "#mp_map_mode_box",
        URL: "/pcsite/map/load/MP_MapModebox.html",
      },
      MAP_NOTIFY_BOX: {
        ID: "#mp_map_notify_box",
        URL: "/pcsite/map/load/MP_MapNotifybox.html",
      },
      MAP_ZOOM_BOX: {
        ID: "#mp_map_zoom_box",
        URL: "/pcsite/map/load/MP_MapZoombox.html",
      },

      POP_CAMHLAYER_BOX: {
        ID: "#mp_pop_camhlayer",
        URL: "/pcsite/map/load/MP_PopCamHLayer.html",
      },
      POP_CAMSPLAYER_BOX: {
        ID: "#mp_pop_camsplayer",
        URL: "/pcsite/map/load/MP_PopCamSPLayer.html",
      },
      POP_WEATHERLAYER_BOX: {
        ID: "#mp_pop_weatherlayer",
        URL: "/pcsite/map/load/MP_PopWeatherLayer.html",
      },
    },
    IMG: {
      FULL_ICON: {
        ON: "/pcsite/map/images/mapiconFullon.svg",
        OFF: "/pcsite/map/images/mapiconFull.svg",
      },
      CLOSE_ICON: "/pcsite/map/images/btnPopClose.svg",
      CAMERA: {
        ROAD: {
          MAP_ICON: "/pcsite/map/images/plotIconCamH.svg",
          ON: "/pcsite/map/images/mapicon_roadcam_on.svg",
          ON_SNOW: "/pcsite/map/images/mapicon_snow_roadcam_on.svg",
          OFF: "/pcsite/map/images/mapicon_roadcam_off.svg",
          OFF_SNOW: "/pcsite/map/images/mapicon_snow_roadcam_off.svg",
          WEB_THUM_PREFIX: "/camera/webthumcam",
        },
        SAPA: {
          MAP_ICON: "/pcsite/map/images/plotIconCamSP.svg",
          ON: "/pcsite/map/images/mapicon_sapacam_on.svg",
          OFF: "/pcsite/map/images/mapicon_sapacam_off.svg",
          WEB_THUM_PREFIX: "/camera/webthumcam",
        },
        DETAIL_PREFIX: "/camera/webcam",
      },
      ACCIDENT_MAP_ICON: process.env.PUBLIC_URL + "/icons/legend_crash.svg",
      LOADING_ICON: "/pcsite/map/images/statusLoading.gif",
      IC_ARROW_ICON: {
        _0_0_0_0: "/pcsite/map/images/icarrow/ic_arrow_0_0_0_0.svg",
        _0_0_0_1: "/pcsite/map/images/icarrow/ic_arrow_0_0_0_1.svg",
        _0_0_1_0: "/pcsite/map/images/icarrow/ic_arrow_0_0_1_0.svg",
        _0_0_1_1: "/pcsite/map/images/icarrow/ic_arrow_0_0_1_1.svg",
        _0_1_0_0: "/pcsite/map/images/icarrow/ic_arrow_0_1_0_0.svg",
        _0_1_0_1: "/pcsite/map/images/icarrow/ic_arrow_0_1_0_1.svg",
        _0_1_1_0: "/pcsite/map/images/icarrow/ic_arrow_0_1_1_0.svg",
        _0_1_1_1: "/pcsite/map/images/icarrow/ic_arrow_0_1_1_1.svg",
        _1_0_0_0: "/pcsite/map/images/icarrow/ic_arrow_1_0_0_0.svg",
        _1_0_0_1: "/pcsite/map/images/icarrow/ic_arrow_1_0_0_1.svg",
        _1_0_1_0: "/pcsite/map/images/icarrow/ic_arrow_1_0_1_0.svg",
        _1_0_1_1: "/pcsite/map/images/icarrow/ic_arrow_1_0_1_1.svg",
        _1_1_0_0: "/pcsite/map/images/icarrow/ic_arrow_1_1_0_0.svg",
        _1_1_0_1: "/pcsite/map/images/icarrow/ic_arrow_1_1_0_1.svg",
        _1_1_1_0: "/pcsite/map/images/icarrow/ic_arrow_1_1_1_0.svg",
        _1_1_1_1: "/pcsite/map/images/icarrow/ic_arrow_1_1_1_1.svg",
      },
      WEATHER_ICON: {
        fcst_sun: "/pcsite/map/images/weather/fcst_sun.svg",
        fcst_cloud: "/pcsite/map/images/weather/fcst_cloud.svg",
        fcst_rain: "/pcsite/map/images/weather/fcst_rain.svg",
        fcst_snow: "/pcsite/map/images/weather/fcst_snow.svg",
        fcst_sleet: "/pcsite/map/images/weather/fcst_sleet.svg",
        fcst_heavySnow: "/pcsite/map/images/weather/fcst_heavySnow.svg",
        sun: "/pcsite/map/images/weather/sun.svg",
        cloud: "/pcsite/map/images/weather/cloud.svg",
        rain: "/pcsite/map/images/weather/rain.svg",
        snow: "/pcsite/map/images/weather/snow.svg",
        sleet: "/pcsite/map/images/weather/sleet.svg",
        heavySnow: "/pcsite/map/images/weather/heavySnow.svg",
        wind01: "/pcsite/map/images/wind/wind01.svg",
        wind02: "/pcsite/map/images/wind/wind02.svg",
        wind03: "/pcsite/map/images/wind/wind03.svg",
        wind04: "/pcsite/map/images/wind/wind04.svg",
        none: "/pcsite/map/images/wind/none.svg",
      },
      POP_WEATHER_ICON: {
        sun: "/pcsite/map/images/weather/popup/sun.svg",
        cloud: "/pcsite/map/images/weather/popup/cloud.svg",
        rain: "/pcsite/map/images/weather/popup/rain.svg",
        snow: "/pcsite/map/images/weather/popup/snow.svg",
        sleet: "/pcsite/map/images/weather/popup/sleet.svg",
        heavySnow: "/pcsite/map/images/weather/popup/heavySnow.svg",
      },
    },
    // 規制メニューテキスト欄に表示する規制種類
    CATEGORY: {
      traffic: [
        "underAdjustment",
        "closed",
        "ramp",
        "snowChain",
        "snowTires",
        "oneLane",
        "jam",
        "accident",
      ],
      winter: [
        "underAdjustment",
        "closed",
        "ramp",
        "snowChain",
        "snowTires",
        "snowPlow",
        "antifreeze",
      ],
      rain: ["underAdjustment", "closed", "ramp", "rainCaution"],
    },
    // 大雨モードに表示する西日本エリア
    RAIN_MODE_AREA: ["area06", "area07", "area08", "area09"],
    INVISIBLE: 0, // featureの非表示constant
    // 規制ポップアップフラグ
    POPUP_DELAY_FLAG: {
      OFF: 0, // 即時非表示
      ON: 1, // delay後非表示
      RESET: 2, // 表示のまま
    },
    MAP_CONFIG: {
      HIT_TOLERANCE: {
        TRAFFIC: 0, // クリックにより取得する円の範囲
        CAMERA: 0, // camera icon pinpoint
        SAPA: 0, // SAPA
        IC: 0, // IC
        WEATHER: 0, // WEATHER
      },
      TILE_SIZE: 256, // tileサイズ(=画像サイズ)
      URL: "/datas/tiles/gray-map/1/map-{x}_{y}.png", // map tile url定義
      EXTENT: [0, 0, 10240, 10240], // 範囲(ピクセル指定)[min x,min y,max x,max y]
      VIEW_EXTENT: [6000, 2980, 8200, 4800], // view extent: [左下x,y , 右上x,y]
      CENTER: [7100, 3900], // 初期イメージマップセンター位置

      // Zoom範囲
      DEF_ZOOM: 0, // 初期zoom
      MIN_ZOOM: 0, // 最少zoom
      MAX_ZOOM: 4, // 最大zoom
      MULTIPLE: 3, // xyzのresolutionに使用(変更により位置座標値が大きく変わるので注意)

      // resolution :  このシステムは 1unit == 1pixel (resolution 1が表示比率 100%)
      ZOOMLV0_RESOLUTION: 2.6228, // MAX_RESOLUTION (最大縮小値)
      ZOOMLV1_RESOLUTION: 1.6908, // ZoomLevel 1 (縮小値)
      ZOOMLV2_RESOLUTION: 1.3576, // ZoomLevel 2 (縮小値)
      ZOOMLV3_RESOLUTION: 1.086, // ZoomLevel 3 (縮小値)
      ZOOMLV4_RESOLUTION: 0.64, // ZoomLevel 4 (最大拡大値)

      //LAYERの定義 ( zindex 0:最下位 )
      LAYER: {
        MAP_LAYER: { id: "BaseMap", zindex: 20 },
        IC_LAYER: { id: "ArrowIc", zindex: 26 },
        ROADCAMERA_LAYER: { id: "RoadCamera", zindex: 28 },
        SAPACAMERA_LAYER: { id: "SAPACamera", zindex: 30 },
        SAPA_LAYER: { id: "SAPACInfo", zindex: 34 }, // SAPA情報レイヤー
        WEATHER_LAYER: { id: "Weather", zindex: 32 }, // 天気
        TRAFFIC: {
          closed: { id: "ClosedLayer", zindex: 16 }, // 通行止め
          accident: { id: "AccidentLayer", zindex: 23 }, // 事故
          snowChain: { id: "SnowChainLayer", zindex: 9 }, // チェーン規制
          snowTires: { id: "SnowTiresLayer", zindex: 8 }, // 冬用タイヤ等装着
          oneLane: { id: "OneLaneLayer", zindex: 6 }, // 片側交互通行
          jam: { id: "JamLayer", zindex: 10 }, // 渋滞
          snowPlow: { id: "SnowPlowLayer", zindex: 4 }, // 除雪作業
          antifreeze: { id: "AntifreezeLayer", zindex: 2 }, // 凍結防止剤散布
          rainCaution: { id: "RainCautionLayer", zindex: 14 }, // 大雨警戒区間
          underAdjustment: { id: "UnderAdjustmentLayer", zindex: 18 }, // 調整中
          ramp: { id: "RampLayer", zindex: 21 }, // ランプ閉鎖
          closed_frame: { id: "ClosedICLayer", zindex: 22 }, // 通行止め(IC枠)
        },
        MOUSEOVER_LAYER: { id: "mouseOverLayer", zindex: 89 },
        DEBUGTILE_LAYER: { id: "debugTileLayer", zindex: 99 },
      },
      // animation表示時間の定義(0:animation無し , 1000 : 1秒)
      DURATION: {
        MOVE: 1000, // area移動
        ZOOM: 250, // zoom反映
      },
    },
    // area center
    AREA: {
      area01: [7947, 7868], // 北海道
      area02: [7438, 5754], // 東北
      area03: [6118, 4327], // 北陸信越
      area04: [7050, 3872], // 関東
      area05: [5525, 3800], // 東海
      area06: [4237, 3087], // 関西
      area07: [2710, 3381], // 中国
      area08: [3152, 2654], // 四国
      area09: [1522, 2099], // 九州沖縄
    },
    // 件数色
    AREA_TRAFFIC_COLOR: {
      COUNT: [10, 30, 10000],
      COLOR: ["mp_color_blue", "mp_color_yellow", "mp_color_red"],
    },
    AREA_NAME_INIT: "---", // エリア名取得失敗時表示
    // ポップアップ順
    POP_UP_INDEX: [
      "closed", // top
      "ramp",
      "snowChain",
      "snowTires",
      "oneLane",
      "jam",
      "snowPlow",
      "antifreeze",
      "rainCaution",
      "accident",
    ], // bottom
    // お知らせ
    NOTIFY: {
      INTERVAL: 100,
      SPEED: 2000, // 表示時間
      INIT: "---", // 初期表示（お知らせが無い場合
    },
    // 交通情報
    TRAFFIC_LAYER: {
      FEATURE_ID: "traffic",
      // color: feature用color ,  color_num: css用color番号
      KINDS: {
        closed: { color: "#000000", color_num: 0, txt: "通行止め" }, // 通行止め
        ramp: { color: "#bf4c7c", color_num: 1, txt: "ランプ閉鎖" }, // ランプ閉鎖
        snowChain: { color: "#de4278", color_num: 10, txt: "チェーン規制" }, // チェーン規制
        snowTires: { color: "#216bd9", color_num: 2, txt: "冬用タイヤ等装着" }, // 冬用タイヤ等装着
        oneLane: { color: "#2db32d", color_num: 3, txt: "片側交互通行" }, // 片側交互通行
        jam: { color: "#ff9400", color_num: 4, txt: "渋滞" }, // 渋滞
        accident: { color: "#d90000", color_num: 5, txt: "事故" }, // 事故・火災
        snowPlow: { color: "#99CCFF", color_num: 6, txt: "除雪作業" }, // 除雪作業
        antifreeze: { color: "#cccc00", color_num: 7, txt: "凍結防止剤散布" }, // 凍結防止剤散布
        rainCaution: { color: "#e63900", color_num: 8, txt: "大雨警戒区間" }, // 大雨警戒区間
        underAdjustment: { color: "#999999", color_num: 9, txt: "調整中" }, // 調整中
        closed_inIc_frame: { color: "#0000FF", color_num: 0, txt: "通行止" }, // 通行止め 流入IC
        closed_outIc_frame: { color: "#FF0000", color_num: 0, txt: "通行止" }, // 通行止め 流出IC
        red: { color: "#CE0613", txt: "気象/運転警戒" },
        yellow: { color: "#F3DF24", txt: "気象/運転注意" },
      },

      LINE_CAP: "butt",
      LINE_WIDTH: 8,
      IC_WIDTH: 2,
      ACCIDENT_SIZE: { RADIUS: 8, WIDTH: 3.5 },
      POPUP_DISPLAY_TIME: 5000, // popupを表示する時間(ms)
      ACCIDENT_CONNECT_FLAG: { ON: "1", OFF: "0" }, // 乗継IC情報
    },
    CAMERA_LAYER: {
      FEATURE_ID: "camera",
      POPUP_DISPLAY_TIME: 5000, // popupを表示する時間(ms)
      KINDS: { SAPA: "sapa", ROAD: "road" },
    },
    SAPA_LAYER: {
      FEATURE_ID: "sapa",
      WIDTH: 2,
      POPUP_DISPLAY_TIME: 5000, // popupを表示する時間(ms)
    },
    IC_LAYER: {
      FEATURE_ID: "ic",
      WIDTH: 2,
      RADIUS: 9,
      POPUP_DISPLAY_TIME: 5000, // popupを表示する時間(ms)
    },
    WEATHER_LAYER: {
      FEATURE_ID: "weather",
      POPUP_DISPLAY_TIME: 5000, // popupを表示する時間(ms)
      NAME: {
        sun: "晴れ",
        cloud: "曇",
        rain: "雨",
        snow: "小雪",
        sleet: "みぞれ",
        heavySnow: "大雪",
      },
    },
  },
};

export const roadIdMap = {
  //tohoku
  東北自動車道: [10170032, 10170035],
  八戸自動車道: [10070022, 10070025],
  "八戸自動車道（八戸方面）": [10070032, 10070035],
  秋田自動車道: [10090022, 10090025],
  山形自動車道: [10130022, 10130025],
  仙台東部道路: [31001702, 31001705],
  磐越自動車道: [10150032, 10150035],
  湯沢横手道路: [31001302, 31001305],
  日本海東北自動車道: [10110022, 10110025],
  東北中央自動車道: [10120022, 10120025],
  常磐自動車道: [10210042, 10210045],
  仙台北部道路: [31001802, 31001805],
  釜石自動車道: [10080022, 10080025],
  仙台南部道路: [31001902, 31001905],
  三陸自動車道: [31001502, 31001505],
  百石道路: [31001102, 31001105],
  青森自動車道: [10070012, 10070015],
  日本海東北道: [10110032, 10110035],
  //kanto
  北関東自動車道: [10220032, 10220035, 10220042, 10220045],
  北関東道: [10220032, 10220035, 10220042, 10220045],
  東京外環道: [10230072, 10230075],
  東関東自動車道: [10270042, 10270045],
  館山自動車道: [10280042, 10280045],
  "東京湾アクアライン連絡道・館山自動車道": [
    10280142, 10280145, 31201702, 31201705,
  ],
  新空港道: [10290042, 10290045],
  関越道: [10350052, 10350055],
  上信越道: [10370052, 10370055],
  長野道: [10390062, 10390065],
  富津館山道路: [31002102, 31002105],
  第三京浜: [31100102, 31100105],
  横浜新道: [31100202, 31100205],
  横横道: [31100302, 31100305],
  横横道金沢支線: [31100312, 31100315],
  圏央道: [31200102, 31200105],
  京葉道路: [31201102, 31201105],
  千葉東金道路: [31201302, 31201305],
  東水戸道路: [31201502, 31201505],
  東京湾アクアライン: [31201902, 31201905],
};

export const regulationRoadRanges = {
  10150032: {
    start: 0,
    end: 166,
  },
  10150035: {
    start: 0,
    end: 166,
  },
  10170032: {
    start: 0,
    end: Infinity,
  },
  10170035: {
    start: 0,
    end: Infinity,
  },
  10210042: {
    start: 0,
    end: Infinity,
  },
  10210045: {
    start: 0,
    end: Infinity,
  },
  10220032: {
    start: 0,
    end: 55,
  },
  10220035: {
    start: 0,
    end: 55,
  },
  10220042: {
    start: 100,
    end: 180,
  },
  10220045: {
    start: 0,
    end: 180,
  },
  10230072: {
    start: 0,
    end: 49,
  },
  10230075: {
    start: 0,
    end: 49,
  },
  10270042: {
    start: 0,
    end: 123,
  },
  10270045: {
    start: 0,
    end: 123,
  },
  10280042: {
    start: 36,
    end: 87,
  },
  10280045: {
    start: 36,
    end: 87,
  },
  10280142: {
    start: 0,
    end: 4,
  },
  10280145: {
    start: 0,
    end: 4,
  },
  10290042: {
    start: 0,
    end: 4,
  },
  10290045: {
    start: 0,
    end: 4,
  },
  10350052: {
    start: 0,
    end: 141,
  },
  10350055: {
    start: 0,
    end: 141,
  },
  10370052: {
    start: 0,
    end: 167,
  },
  10370055: {
    start: 0,
    end: 167,
  },
  10390062: {
    start: 33,
    end: 75,
  },
  10390065: {
    start: 33,
    end: 75,
  },
  31002102: {
    start: 87,
    end: 106,
  },
  31002105: {
    start: 87,
    end: 106,
  },
  31100102: {
    start: 0,
    end: 16,
  },
  31100105: {
    start: 0,
    end: 16,
  },
  31100202: {
    start: 0,
    end: 11,
  },
  31100205: {
    start: 0,
    end: 11,
  },
  31100302: {
    start: 0,
    end: 34,
  },
  31100305: {
    start: 0,
    end: 34,
  },
  31100312: {
    start: 0,
    end: 4,
  },
  31100315: {
    start: 0,
    end: 4,
  },
  31200102: {
    start: 48,
    end: 282,
  },
  31200105: {
    start: 48,
    end: 282,
  },
  31201102: {
    start: 0,
    end: 36,
  },
  31201105: {
    start: 0,
    end: 36,
  },
  31201302: {
    start: 0,
    end: 16,
  },
  31201305: {
    start: 0,
    end: 16,
  },
  31201502: {
    start: 180,
    end: 191,
  },
  31201505: {
    start: 180,
    end: 191,
  },
  31201702: {
    start: 15,
    end: 24,
  },
  31201705: {
    start: 15,
    end: 24,
  },
  31201902: {
    start: 0,
    end: 15,
  },
  31201905: {
    start: 0,
    end: 15,
  },
};
