export const weatherSchema = (pointnos) => ({
  col: "RU_411022700",
  condition: [
    {
      $match: {
        "data.pointno": {
          $in: pointnos,
        },
      },
    },
  ],
});

export const cameraSchema = (tagid) => ({
  col: "camera",
  condition: [
    {
      $match: {
        tagid: tagid,
        time: {
          $gt: Math.round(new Date() / 1000) - 3600,
        },
      },
    },
    {
      $sort: {
        time: 1,
      },
    },
  ],
});
