import { useState } from "react";
import { createModel } from "hox";

function useNetworkError() {
  const [error, setError] = useState(false);
  return {
    error, setError
  };
}

export default createModel(useNetworkError);