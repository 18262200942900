import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import "focus-visible/dist/focus-visible";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { DeformedMap } from "./map/Map";

const breakpoints = createBreakpoints({
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  "2xl": "1536px",
});
const theme = extendTheme({
  breakpoints,
  styles: {
    global: {
      "#App, #root": {
        height: "100%",
        overflowX: "hidden",
      },
      "html, body": {
        height: "100%",
        overflow: "hidden",
      },
    },
  },
});

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("G-QZQQ2V26RX");
    }
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route index path="/" element={<DeformedMap />} />
          <Route path="/app" element={<DeformedMap />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
