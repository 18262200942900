import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/react";
import React from "react";

export default function ToggleDisplayIcon({
  mode,
  isIconVisible,
  setIsIconVisible,
}) {
  if (!["risk", "camera"].includes(mode)) return null;
  return (
    <Box
      m={["10px", "80px"]}
      zIndex={0}
      position={"absolute"}
      bottom="10%"
      right="10px"
    >
      {isIconVisible ? (
        <ViewIcon
          boxSize={"10%"}
          float={"right"}
          onClick={() => setIsIconVisible(false)}
        />
      ) : (
        <ViewOffIcon
          boxSize={"10%"}
          float={"right"}
          onClick={() => setIsIconVisible(true)}
        />
      )}
    </Box>
  );
}
