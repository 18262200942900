import { Box, Image, Text } from "@chakra-ui/react";
import { CircularProgress, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useInterval, useRequest } from "ahooks";
import * as moment from "moment";
import React, { useState } from "react";
import { MONITORING_ENDPOINT } from "./const";
import { cameraSchema } from "./querys";

const useStyles = makeStyles({
  button: {
    color: "white",
  },
});

export function Camera({ tagId, name, setVisible }) {
  const { data, loading } = useRequest(
    {
      url: MONITORING_ENDPOINT,
      method: "post",
      body: JSON.stringify(cameraSchema(tagId.toString())),
    },
    {
      ready: tagId,
      refreshDeps: [tagId],
      pollingInterval: 300000,
    }
  );
  const [index, setIndex] = useState(0);

  useInterval(() => {
    if (data && data.length > 0)
      setIndex(index + 1 > data.length - 1 ? 0 : index + 1);
  }, 500);

  const classes = useStyles();

  if (loading) {
    return (
      <Box
        height={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Box
        color="white"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p="20px"
      >
        <Box
          w="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text>{name}</Text>
          <IconButton
            className={classes.button}
            onClick={() => {
              setVisible(false);
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        <Typography variant="h3">調整中</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" p="15px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        color="white"
      >
        <Text>{name}</Text>
        <Text>
          {moment(data[index].time * 1000).format("YYYY/MM/DD HH:mm")}{" "}
        </Text>
        <IconButton
          className={classes.button}
          onClick={() => {
            setVisible(false);
          }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <Image
        src={"data:image/png;base64," + data[index].image.Data}
        alt="imgMovie"
      />
    </Box>
  );
}
