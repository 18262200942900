import { Fill, Icon, Stroke, Style } from "ol/style";
import { CM_CONSTANT } from "./const";
import { zip, sum, max, min } from "lodash";

const changeY = function (coordinate_y) {
  return CM_CONSTANT.MAP.MAP_CONFIG.EXTENT[3] - coordinate_y;
};

const getRoadFragment = (roadPart, start, end) => {
  const points = zip(roadPart.x, roadPart.y);
  const edges = [];
  const fragments = [];
  let length = 0;
  for (let i = 0; i < points.length - 1; i++) {
    const pointA = points[i];
    const pointB = points[i + 1];
    let edgeLength = Math.sqrt(
      Math.pow(pointA[0] - pointB[0], 2) + Math.pow(pointA[1] - pointB[1], 2)
    );
    edges.push({
      start: pointA,
      end: pointB,
      length: edgeLength,
      startLength: length,
      endLength: length + edgeLength,
    });
    length += edgeLength;
  }
  const sumLength = sum(edges.map((e) => e.length));
  const kpLength = roadPart.kpEnd - roadPart.kpStart;
  const startLength =
    ((max([start, roadPart.kpStart]) - roadPart.kpStart) / kpLength) *
    sumLength;
  const endLength =
    sumLength -
    ((roadPart.kpEnd - min([end, roadPart.kpEnd])) / kpLength) * sumLength;
  edges.forEach((e) => {
    if (endLength > e.startLength && startLength < e.endLength) {
      fragments.push({
        startX:
          startLength > e.startLength
            ? ((startLength - e.startLength) / (e.endLength - e.startLength)) *
                (e.end[0] - e.start[0]) +
              e.start[0]
            : e.start[0],
        startY:
          startLength > e.startLength
            ? ((startLength - e.startLength) / (e.endLength - e.startLength)) *
                (e.end[1] - e.start[1]) +
              e.start[1]
            : e.start[1],
        endX:
          endLength < e.endLength
            ? e.end[0] -
              ((e.endLength - endLength) / (e.endLength - e.startLength)) *
                (e.end[0] - e.start[0])
            : e.end[0],
        endY:
          endLength < e.endLength
            ? e.end[1] -
              ((e.endLength - endLength) / (e.endLength - e.startLength)) *
                (e.end[1] - e.start[1])
            : e.end[1],
      });
    }
  });
  return fragments;
};

const getAllRoadFragment = (roadPart, start, end) => {
  const intersectionKp =
    Math.min(end, roadPart.kpEnd) - Math.max(start, roadPart.kpStart);
  if (roadPart.kpEnd - roadPart.kpStart >= 0.5 && intersectionKp < 0.5) {
    return [];
  }
  const points = zip(roadPart.x, roadPart.y);
  const edges = [];
  const fragments = [];
  for (let i = 0; i < points.length - 1; i++) {
    const pointA = points[i];
    const pointB = points[i + 1];
    edges.push({
      start: pointA,
      end: pointB,
    });
  }
  edges.forEach((e) => {
    fragments.push({
      startX: e.start[0],
      startY: e.start[1],
      endX: e.end[0],
      endY: e.end[1],
    });
  });
  return fragments;
};

const getConvertedPoints = (roadPart) => {
  const points = zip(roadPart.x.split(","), roadPart.y.split(","));
  const convertedPoints = [];
  for (let i = 0; i < points.length - 1; i++) {
    const pointA = points[i];
    const pointB = points[i + 1];
    convertedPoints.push({
      startX: Math.round(pointA[0]),
      endX: Math.round(pointB[0]),
      startY: Math.round(pointA[1]),
      endY: Math.round(pointB[1]),
    });
  }
  return convertedPoints;
};

const changeBaseXyToPoint = function (coordinate, shape_kinds) {
  var retCoordinate = [];
  try {
    if (shape_kinds === "point") {
      // point表示 例： 事故
      retCoordinate = [coordinate[0].startX, changeY(coordinate[0].startY)];
    } else if (shape_kinds === "line") {
      // 渋滞などのライン規制
      for (let i = 0, l = coordinate.length; i < l; i++) {
        if (Array.isArray(coordinate[i])) {
          for (var j = 0, jl = coordinate[i].length; j < jl; j++) {
            retCoordinate.push([
              [coordinate[i][j].startX, changeY(coordinate[i][j].startY)],
              [coordinate[i][j].endX, changeY(coordinate[i][j].endY)],
            ]);
          }
        } else {
          // hash
          retCoordinate.push([
            [coordinate[i].startX, changeY(coordinate[i].startY)],
            [coordinate[i].endX, changeY(coordinate[i].endY)],
          ]);
        }
      }
    } else if (shape_kinds === "shape") {
      // ランプ閉鎖
      for (let i = 0, l = coordinate.length; i < l; i++) {
        retCoordinate.push([
          [coordinate[i].startX, changeY(coordinate[i].startY)],
          [coordinate[i].endX, changeY(coordinate[i].startY)],
          [coordinate[i].endX, changeY(coordinate[i].endY)],
          [coordinate[i].startX, changeY(coordinate[i].endY)],
        ]);
      }
    }
  } catch (e) {}
  return retCoordinate;
};

const toTrafficKind = (kiseiCode, reasonCode, jyutaiLen) => {
  if (kiseiCode === 1) {
    return "closed";
  } else if (reasonCode >= 201 && reasonCode <= 219) {
    return "accident";
  } else if (kiseiCode === 61) {
    return "snowChain";
  } else if (kiseiCode === 62) {
    return "snowTires";
  } else if (reasonCode === 456) {
    return "snowPlow";
  } else if (reasonCode === 457) {
    return "antifreeze";
  } else if (kiseiCode === 25) {
    return "oneLane";
  } else if (jyutaiLen > 0) {
    return "jam";
  } else {
    return null;
  }
};

const hexToRgb = function (hex, opacity) {
  hex = hex.replace("#", "");
  var bigint = parseInt(hex, 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;

  return "rgba(" + r + "," + g + "," + b + ", " + opacity + ")";
};

const iconStyle = function (traffic_kinds, is_invisible) {
  const scale = 1;
  if (traffic_kinds === "accident") {
    return new Style({
      image: new Icon({
        opacity: 1,
        src: CM_CONSTANT.MAP.IMG.ACCIDENT_MAP_ICON,
        scale: scale < 0.73 ? 0.73 : scale,
        imgSize: [20, 20], // svgファイルのためIE対応に必要
      }),
    });
  } else if (
    ["ramp", "closed_inIc_frame", "closed_outIc_frame"].includes(traffic_kinds)
  ) {
    if (typeof is_invisible === "undefined" || is_invisible == null) {
      is_invisible = 1;
    }
    return new Style({
      stroke: new Stroke({
        width: CM_CONSTANT.MAP.TRAFFIC_LAYER.IC_WIDTH * scale,
        lineCap: CM_CONSTANT.MAP.TRAFFIC_LAYER.LINE_CAP,
        color: hexToRgb(
          CM_CONSTANT.MAP.TRAFFIC_LAYER.KINDS[traffic_kinds].color,
          is_invisible
        ),
      }),
      fill: new Fill({
        color: hexToRgb(
          CM_CONSTANT.MAP.TRAFFIC_LAYER.KINDS[traffic_kinds].color,
          0
        ),
      }),
    });
  } else {
    return (feature, resolution) => {
      return new Style({
        stroke: new Stroke({
          width: CM_CONSTANT.MAP.TRAFFIC_LAYER.LINE_WIDTH / resolution,
          lineCap: CM_CONSTANT.MAP.TRAFFIC_LAYER.LINE_CAP,
          color: hexToRgb(
            CM_CONSTANT.MAP.TRAFFIC_LAYER.KINDS[traffic_kinds].color,
            1
          ),
        }),
        fill: new Fill({
          color: hexToRgb(
            CM_CONSTANT.MAP.TRAFFIC_LAYER.KINDS[traffic_kinds].color,
            1
          ),
        }),
      });
    };
  }
};

const weatherCodeToName = function (code) {
  switch (code) {
    case 100:
    case 500:
    case 600:
      return "sunny";
    case 200:
    case 800:
    case 700:
      return "cloudy";
    case 300:
      return "rainy";
    case 400:
    case 1000:
      return "snowy";
    case 430:
      return "sleet";
    default:
      return "unknown";
  }
};

const winSpeedToColor = function (spd) {
  if (spd / 10 >= 15) {
    return "#be10df";
  } else if (spd / 10 >= 10) {
    return "#eec414";
  } else {
    return "#1627ba";
  }
};

const preprocess = (data) => (data === 9999 ? 0 : data / 10);
const preprocessSnow = (data) => (Math.abs(data) === 9999 ? -1 : data);

const determineStrongWind = (r) => {
  const windSpeed = preprocess(r.WNDSPD);
  if (windSpeed >= 15) {
    return {
      name: "strongwind",
      level: "red",
    };
  } else if (windSpeed >= 10) {
    return {
      name: "strongwind",
      level: "yellow",
    };
  } else {
    return null;
  }
};

const determineBadView = (r) => {
  const precipation = preprocess(r.PREC_1hour_Total);
  if (precipation >= 40) {
    return {
      name: "badview",
      level: "red",
    };
  } else if (precipation >= 20) {
    return {
      name: "badview",
      level: "yellow",
    };
  } else {
    return null;
  }
};

const determineSlipping = (r) => {
  const snowFall = preprocessSnow(r.SNWFLL_1hour_Total);
  if (snowFall >= 2) {
    return {
      name: "slipping",
      level: "red",
    };
  } else if (snowFall >= 0) {
    return {
      name: "slipping",
      level: "yellow",
    };
  } else {
    return null;
  }
};

const determineRisks = (r) => {
  if (!r) return [];
  return [
    determineStrongWind(r),
    determineBadView(r),
    determineSlipping(r),
  ].filter((l) => l);
};

const toRiskIcon = (risk) => {
  if (risk) {
    return `${process.env.PUBLIC_URL}/risk_icons/${risk.name}_${risk.level}.png`;
  } else {
    return process.env.PUBLIC_URL + "/risk_icons/normal.png";
  }
};

export {
  getRoadFragment,
  getAllRoadFragment,
  changeBaseXyToPoint,
  getConvertedPoints,
  iconStyle,
  toTrafficKind,
  weatherCodeToName,
  winSpeedToColor,
  determineStrongWind,
  determineBadView,
  determineSlipping,
  determineRisks,
  hexToRgb,
  toRiskIcon,
};
