import { useRequest } from "ahooks";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Fill, Style, Text, Circle as CircleStyle } from "ol/style";
import { useEffect, useState } from "react";
import { changeBaseXyToPoint } from "./utils";
import jaconv from "jaconv";
import { useBreakpointValue } from "@chakra-ui/react";

const calculateRotation = (ic) => {
  const deltaY = ic.nameEndY - ic.nameStartY;
  const deltaX = ic.nameEndX - ic.nameStartX;
  if (Math.abs(deltaY) > Math.abs(deltaX)) {
    return Math.PI / 2;
  } else {
    return 0;
  }
};

const generateTextFeature = ({
  rotation,
  ic,
  leftOrTop,
  isSmartPhone,
  name,
  isSmart,
}) => {
  const textFeature = new Feature(
    new Point(
      changeBaseXyToPoint(
        [
          {
            startX:
              rotation !== 0
                ? ic.nameEndX
                : leftOrTop
                ? ic.nameEndX
                : ic.nameStartX,
            startY:
              rotation === 0
                ? ic.nameEndY
                : leftOrTop
                ? ic.nameEndY
                : ic.nameStartY,
          },
        ],
        "point"
      )
    )
  );
  textFeature.setStyle(
    new Style({
      text: new Text({
        font: isSmartPhone
          ? `600 9px/1.1 'Kosugi Maru'`
          : `600 14px/0.95 'Kosugi Maru'`,
        text:
          rotation !== 0 ? name.replace("ー", "｜").split("").join("\n") : name,
        fill: new Fill({
          color: isSmart ? "#0D7514" : "black",
        }),
        textAlign: rotation === 0 ? (leftOrTop ? "right" : "left") : "right",
        textBaseline: rotation === 0 ? "bottom" : leftOrTop ? "bottom" : "top",
        offsetX: rotation === 0 ? 0 : isSmartPhone ? -2 : -7,
        offsetY:
          rotation === 0
            ? isSmart
              ? isSmartPhone
                ? -3
                : -5
              : isSmartPhone
              ? -2
              : -8
            : 0,
      }),
    })
  );
  return textFeature;
};

export function useICLayer() {
  const { data } = useRequest("/api/map/ic", {});

  const isSmartPhone = useBreakpointValue({ base: true, md: false });

  const [icLayers] = useState(() => [
    new VectorLayer({
      source: new VectorSource(),
    }),
    new VectorLayer({
      source: new VectorSource(),
      maxResolution: isSmartPhone ? 1.7 : 1.35,
    }),
  ]);

  useEffect(() => {
    if (!data) return;
    let features = [];
    let mainFeatures = [];
    data.forEach((ic) => {
      const rotation = calculateRotation(ic);
      const leftOrTop =
        rotation === 0 ? ic.nameStartX < ic.pointX : ic.nameEndY < ic.pointY;
      let name = jaconv.toZenAscii(ic.icName);
      if (name !== "東金ＪＣＴ・ＩＣ") {
        name = name
          .replace("ＩＣ", "")
          .replace("IC", "")
          .replace("ＪＣＴ", "Ｊ");
      }
      if (name === "厚木ＰＡスマート") {
        name = "厚木ＰＡ\nスマート";
      }
      const isSmart =
        ic.icName.includes("スマート") &&
        (ic.icName.includes("SA") || ic.icName.includes("PA"));

      let textFeature = generateTextFeature({
        rotation,
        ic,
        leftOrTop,
        isSmartPhone,
        name,
        isSmart,
      });
      let textFeatureSecond;
      if (
        [
          "小黒川PAスマート",
          "駒ヶ岳SAスマートＩＣ",
          "座光寺PAスマートIC",
        ].includes(ic.icName)
      ) {
        textFeature = generateTextFeature({
          rotation,
          ic,
          leftOrTop,
          isSmartPhone,
          name: name.replace("スマート", ""),
          isSmart,
        });
        textFeatureSecond = generateTextFeature({
          rotation,
          ic: {
            ...ic,
            nameStartX: ic.nameStartX + 15,
            nameEndX: ic.nameEndX + 15,
          },
          leftOrTop,
          isSmartPhone,
          name: "スマート",
          isSmart,
        });
      }
      const circleFeature = new Feature(
        new Point(
          changeBaseXyToPoint(
            [
              {
                startX: ic.pointX,
                startY: ic.pointY,
              },
            ],
            "point"
          )
        )
      );
      circleFeature.setStyle(
        new Style({
          image: new CircleStyle({
            radius: 2,
            fill: new Fill({ color: "#b3b3b3" }),
          }),
        })
      );
      if (isSmart) {
        const x = 15;
        [
          [
            ic.pointX + x * Math.cos((ic.angle / 180) * Math.PI),
            ic.pointY + x * Math.sin((ic.angle / 180) * Math.PI),
          ],
          [
            ic.pointX - x * Math.cos((ic.angle / 180) * Math.PI),
            ic.pointY - x * Math.sin((ic.angle / 180) * Math.PI),
          ],
        ].forEach((point) => {
          const rectFeature = new Feature(
            new Point(
              changeBaseXyToPoint(
                [
                  {
                    startX: point[0],
                    startY: point[1],
                  },
                ],
                "point"
              )
            )
          );
          rectFeature.setStyle(
            new Style({
              image: new CircleStyle({
                radius: 2,
                fill: new Fill({ color: "#0D7514" }),
              }),
            })
          );
          features.push(rectFeature);
        });
      }
      if (ic.minimumZoomLevel > 0 && !name.includes("Ｊ")) {
        features.push(textFeature);
        if (textFeatureSecond) {
          features.push(textFeatureSecond);
        }
        mainFeatures.push(circleFeature);
      } else {
        if (textFeatureSecond) {
          mainFeatures.push(textFeatureSecond);
        }
        mainFeatures.push(textFeature, circleFeature);
      }
    });
    icLayers[0].setSource(new VectorSource({ features: mainFeatures }));
    icLayers[1].setSource(new VectorSource({ features }));
  }, [data, icLayers, isSmartPhone]);

  return icLayers;
}
