import { AddIcon, MinusIcon, WarningTwoIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useInterval, useRequest } from "ahooks";
import { find, reverse } from "lodash";
import { defaults as defaultControls, ZoomSlider } from "ol/control";
import { defaults as defaultInteractions } from "ol/interaction";
import { Tile } from "ol/layer";
import Map from "ol/Map";
import "ol/ol.css";
import { Projection } from "ol/proj";
import XYZ from "ol/source/XYZ";
import TileGrid from "ol/tilegrid/TileGrid";
import View from "ol/View";
import React, { useEffect, useMemo, useState } from "react";
import weathernewsLogo from "../images/dw_header.png";
import { CM_CONSTANT, smartPhoneCoefficient } from "./const";
import Legend from "./Legend";
import ToggleDisplayIcon from "./ToggleDisplayIcon";
import { useCameraIconsLayer } from "./useCameraIcon";
import { useICLayer } from "./useICLayer";
import useNetworkError from "./useNetworkError";
import useResetTimer from "./useResetTimer";
import { useRiskIconsLayer } from "./useRiskIcons";
import { useSAPALayers } from "./useSAPALayers";

// export function WrappedDeformedMap() {
//   const state = useOrientation();

//   return state.angle === 90 ||
//     state.type === "landspace-primary" ||
//     state.angle === -90 ? (
//     <Box
//       w="100%"
//       h="100%"
//       justifyContent={"center"}
//       alignItems={"center"}
//       display={"flex"}
//     >
//       <Text fontWeight={"bold"} color={"red.400"} fontSize="2xl">
//         画面を縦向きにしてご使用ぐださい。
//       </Text>
//     </Box>
//   ) : (
//     <DeformedMap></DeformedMap>
//   );
// }

export function DeformedMap() {
  const [isIconVisible, setIconVisible] = useState(true);
  const { error: networkError } = useNetworkError();
  const isSmartPhone = useBreakpointValue({ base: true, md: false });
  const icLayers = useICLayer();
  const sapaLayers = useSAPALayers();
  const resolutions = useMemo(
    () =>
      [
        CM_CONSTANT.MAP.MAP_CONFIG.ZOOMLV1_RESOLUTION,
        CM_CONSTANT.MAP.MAP_CONFIG.ZOOMLV2_RESOLUTION,
        CM_CONSTANT.MAP.MAP_CONFIG.ZOOMLV3_RESOLUTION,
        CM_CONSTANT.MAP.MAP_CONFIG.ZOOMLV4_RESOLUTION,
      ].map((x) => x * (isSmartPhone ? smartPhoneCoefficient : 1)),
    [isSmartPhone]
  );
  const {
    riskIconsLayer,
    roadLayers: riskRoadLayers,
    callback,
    component,
    setVisible: setVisibleWeather,
  } = useRiskIconsLayer();
  const {
    layer: cameraIconsLayer,
    callback: cameraIconsCallback,
    component: cameraIconsComponent,
    setVisible: setVisibleCamera,
  } = useCameraIconsLayer();
  const [map, setMap] = useState(null);
  const [mode, setMode] = useState("risk");
  const { timer, reset: resetTimer, setTimer } = useResetTimer();
  const { data: announcements } = useRequest("/api/announcements", {
    pollingInterval: 60000,
    refreshOnWindowFocus: true,
  });
  useInterval(() => {
    setTimer((t) => t + 1);
  }, 1000);

  useEffect(() => {
    const projection = new Projection({
      units: "pixels",
      code: "pixel",
      extent: CM_CONSTANT.MAP.MAP_CONFIG.EXTENT,
      worldExtent: CM_CONSTANT.MAP.MAP_CONFIG.EXTENT,
    });

    const center = CM_CONSTANT.MAP.MAP_CONFIG.CENTER;

    const mapLayers = [
      new Tile({
        source: new XYZ({
          url: process.env.PUBLIC_URL + "/map/tiles/{z}/{x}/{-y}.png",
          crossOrigin: "",
          tileGrid: new TileGrid({
            extent: CM_CONSTANT.MAP.MAP_CONFIG.EXTENT,
            resolutions: [64, 32, 16, 8, 4, 2],
          }),
          projection,
          zDirection: -1,
          tilePixelRatio: 2,
        }),
      }),
    ];

    var map = new Map({
      target: document.getElementById("map"),
      view: new View({
        center: center,
        projection: projection,
        resolution: resolutions[0],
        resolutions: resolutions,
        extent: CM_CONSTANT.MAP.MAP_CONFIG.VIEW_EXTENT,
      }),
      interactions: defaultInteractions({
        doubleClickZoom: false,
        altShiftDragRotate: false,
        constrainResolution: true,
        dragPan: true,
        pinchRotate: false,
        pinchZoom: true,
        mouseWheelZoom: true,
      }),
      loadTilesWhileInteracting: true,
      controls: defaultControls({
        attribution: false,
        zoom: false,
      }),
      layers: [
        ...mapLayers,
        ...riskRoadLayers,
        cameraIconsLayer,
        ...icLayers,
        ...sapaLayers,
        riskIconsLayer,
      ],
    });
    if (!isSmartPhone) {
      map.addControl(new ZoomSlider());
    }

    // map.on("moveend", function (event) {
    //   console.log("moveend");
    //   resetTimer();
    // });

    map.on("click", function (evt) {
      console.log("click", evt.coordinate);
      resetTimer();
      let features = [];
      map.forEachFeatureAtPixel(
        evt.pixel,
        function (feature, layer) {
          features.push(feature);
          return feature;
        },
        { hitTolerance: 7 }
      );
      callback(features, evt.coordinate);
      cameraIconsCallback(features, evt.coordinate);
    });

    let selected = null;
    map.on("pointermove", function (e) {
      if (selected !== null) {
        const style = selected.getStyle();
        style.getImage().setOpacity(0.7);
        selected.setStyle(style);
        selected = null;
      }

      map.forEachFeatureAtPixel(e.pixel, function (f) {
        if (f.get("points")) {
          selected = f;
          const style = f.getStyle();
          style.getImage().setOpacity(1.0);
          f.setStyle(style);
          return true;
        }
      });
    });

    setMap(map);
  }, [
    isSmartPhone,
    callback,
    cameraIconsCallback,
    cameraIconsLayer,
    icLayers,
    riskIconsLayer,
    riskRoadLayers,
    sapaLayers,
    resetTimer,
    resolutions,
  ]);

  useEffect(() => {
    if (!map || !cameraIconsLayer || !riskIconsLayer) return;
    riskIconsLayer.setVisible(mode === "risk" && isIconVisible);
    cameraIconsLayer.setVisible(mode === "camera" && isIconVisible);
    riskRoadLayers.forEach((l) => {
      l.setVisible(mode === "risk");
    });
  }, [
    cameraIconsLayer,
    map,
    mode,
    riskIconsLayer,
    riskRoadLayers,
    isIconVisible,
  ]);

  useEffect(() => {
    if (!map) return;
    if (timer > 300) {
      console.log("timer > 300, reset");
      resetTimer();
      map.getView().setCenter(CM_CONSTANT.MAP.MAP_CONFIG.CENTER);
      map.getView().setZoom(1);
      setVisibleWeather(false);
      setVisibleCamera(false);
      setMode("risk");
    }
  }, [timer, map, resetTimer, setVisibleWeather, setVisibleCamera]);

  return (
    <Flex w="100%" h="100%" flexDir="column">
      <Box
        display={networkError ? "flex" : "none"}
        width="100vw"
        height="100vh"
        position="absolute"
        justifyContent="center"
        alignItems="center"
      >
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2} fontFamily="Kosugi Maru">
            現在利用できません
          </AlertTitle>
        </Alert>
      </Box>
      <Box
        display={networkError ? "none" : "flex"}
        flexDirection="column"
        width="100%"
        bgColor={"white"}
        h="10%"
        justifyContent="center"
        alignItems="center"
        pointerEvents="none"
        hidden={!isSmartPhone}
      >
        <Image src={weathernewsLogo} alt="imgMovie" h="80%" />
      </Box>
      <Box
        id="map"
        display={networkError ? "none" : "block"}
        width="100%"
        flex={1}
      ></Box>
      {cameraIconsComponent}
      {component}
      <IconButton
        background="#797f93"
        display={networkError ? "none" : "block"}
        pos="absolute"
        left={0}
        top={0}
        m={8}
        onClick={() => {
          const resolution = map.getView().getResolution();
          const newResolution = find(resolutions, (r) => r < resolution);
          if (newResolution) {
            map.getView().setResolution(newResolution);
          }
        }}
        icon={<AddIcon />}
        hidden={isSmartPhone}
      />
      <IconButton
        background="#797f93"
        display={networkError ? "none" : "block"}
        pos="absolute"
        left={0}
        top={12}
        m={8}
        onClick={() => {
          let localResolutions = [...resolutions];
          reverse(localResolutions);
          const resolution = map.getView().getResolution();
          const newResolution = find(localResolutions, (r) => r > resolution);
          if (newResolution) {
            map.getView().setResolution(newResolution);
          }
        }}
        icon={<MinusIcon />}
        hidden={isSmartPhone}
      />
      <Flex
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        alignContent={"center"}
      >
        <Legend mode={mode} />
        <ToggleDisplayIcon
          mode={mode}
          isIconVisible={isIconVisible}
          setIsIconVisible={setIconVisible}
        />
      </Flex>
      {announcements && announcements.length > 0 && (
        <Flex
          hidden={!isSmartPhone}
          display={networkError ? "none" : "block"}
          pos="absolute"
          bottom={0}
          right={0}
          flexDir={"column"}
        >
          <Box
            width="100%"
            display={"flex"}
            justifyContent="flex-end"
            alignItems={"center"}
            p="4"
          >
            <Button
              onClick={() => {
                if (announcements.length > 0) {
                  window.location.href = announcements[0].url;
                }
              }}
              p="2"
              bg="#B3424A"
              color="white"
              leftIcon={<WarningTwoIcon />}
            >
              緊急情報
            </Button>
          </Box>
        </Flex>
      )}
    </Flex>
  );
}
