import { Box } from "@chakra-ui/react";
import { useRequest } from "ahooks";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Icon, Style } from "ol/style";
import React, { useCallback, useEffect, useState } from "react";
import { Camera } from "./Camera";
import { changeBaseXyToPoint } from "./utils";

export function useCameraIconsLayer() {
  const [visible, setVisible] = useState(false);

  const [tagId, setTagId] = useState(null);
  const [name, setName] = useState(null);

  const [layer] = useState(
    () =>
      new VectorLayer({
        source: new VectorSource(),
        zIndex: 100,
      })
  );
  const { data } = useRequest("/api/map/icon", {});

  const [component, setComponent] = useState(null);

  const callback = useCallback((features, position) => {
    setVisible(false);
    for (const feature of features) {
      if (feature.get("camera_tag")) {
        setTagId(feature.get("camera_tag"));
        setName(feature.get("camera_name"));
        setVisible(true);
        // console.log(feature.get("camera_tag"),feature.get("camera_name"))
      }
    }
  }, []);

  useEffect(() => {
    setComponent(
      <Box
        minW={["100%", "280px"]}
        pos="absolute"
        bottom={0}
        left={0}
        bgColor="rgba(0, 0, 0, 0.78)"
        zIndex={2}
      >
        <Camera tagId={tagId} name={name} setVisible={setVisible} />
      </Box>
    );
  }, [data, name, setComponent, tagId]);

  useEffect(() => {
    if (!data) return;
    const features = data
      .filter((i) => i.iconType === "camera")
      .map(
        (i) =>
          new Feature({
            geometry: new Point(
              changeBaseXyToPoint(
                [
                  {
                    startX: i.pointX,
                    startY: i.pointY,
                  },
                ],
                "point"
              )
            ),
            camera_tag: i.cameraId,
            camera_name: i.name,
          })
      );
    features.forEach((f) => {
      f.setStyle(
        (feature, resolution) =>
          new Style({
            image: new Icon({
              crossOrigin: "anonymous",
              scale: 0.02,
              src: process.env.PUBLIC_URL + "/icons/camera-solid.png",
            }),
          })
      );
    });
    layer.setSource(new VectorSource({ features }));
  }, [data, layer]);

  return {
    layer,
    component: visible ? component : null,
    callback,
    setVisible,
  };
}
