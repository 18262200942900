import { Box, useBreakpointValue } from "@chakra-ui/react";
import { useRequest } from "ahooks";
import * as moment from "moment";
import Feature from "ol/Feature";
import { MultiLineString, Point } from "ol/geom";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Icon, Style } from "ol/style";
import * as R from "ramda";
import React, { useCallback, useEffect, useState } from "react";
import { Weather } from "./Weather";
import { roadIdMap } from "./const";
import {
  changeBaseXyToPoint,
  determineRisks,
  getAllRoadFragment,
  getRoadFragment,
  iconStyle,
  toRiskIcon,
  weatherCodeToName,
} from "./utils";

export function useRiskIconsLayer() {
  const isSmartPhone = useBreakpointValue({ base: true, md: false });
  const [visible, setVisible] = useState(false);

  const [selected, setSelected] = useState([]);

  const { data: road, loading: roadLoading } = useRequest("/api/map/road", {
    formatResult: (r) =>
      r.map((i) => {
        const reversed = parseInt(i.kp0) > parseInt(i.kp1);
        let x = i.x.split(",").map((i) => parseInt(i));
        let y = i.y.split(",").map((i) => parseInt(i));
        if (reversed) {
          x.reverse();
          y.reverse();
        }
        return {
          ...i,
          kpStart: Math.min(parseFloat(i.kp0), parseFloat(i.kp1)),
          kpEnd: Math.max(parseFloat(i.kp0), parseFloat(i.kp1)),
          x,
          y,
        };
      }),
  });

  const { data: icons } = useRequest("/api/map/icon", {
    initialData: [],
    formatResult: (r) => r.filter((i) => i.iconType === "weather"),
  });

  const { data, error, loading } = useRequest(
    {
      url: icons
        ? `/api/weather?pointIds=${R.map(
            R.prop("pointNo"),
            R.flatten(R.map(R.prop("points"), icons))
          ).join(",")}`
        : null,
    },
    {
      formatResult: (r) =>
        r.map((x) => ({ ...x, forecast_data: JSON.parse(x.forecast_data) })),
      ready: icons,
      refreshDeps: [icons],
      pollingInterval: 60000,
      refreshOnWindowFocus: true,
    }
  );

  const [riskIconsLayer] = useState(() => {
    return new VectorLayer({
      source: new VectorSource(),
    });
  });

  const [roadLayers] = useState(() => [
    new VectorLayer({
      source: new VectorSource(),
    }),
    new VectorLayer({
      source: new VectorSource(),
    }),
  ]);

  const [component, setComponent] = useState(null);

  const callback = useCallback((features, position) => {
    setVisible(false);
    for (const feature of features) {
      if (feature.get("points")) {
        setSelected(feature.get("points"));
        setVisible(true);
      }
    }
  }, []);

  useEffect(() => {
    if (!data) return;
    setComponent(
      <Box pos="relative">
        <Box
          minW={["100%", "280px"]}
          pos="absolute"
          bottom={0}
          left={0}
          bgColor="white"
          zIndex={2}
        >
          <Weather
            selectedData={
              selected.length > 0
                ? R.find((f) => f.pointno === selected[0].pointNo, data)
                : null
            }
            data={data}
            points={selected}
            setVisible={setVisible}
          />
        </Box>
      </Box>
    );
  }, [data, selected, setComponent]);

  useEffect(() => {
    if (loading || roadLoading) return;
    let features = [];
    let redRoadFeatures = [];
    let yellowRoadFeatures = [];
    const now = moment().utc();
    now.set("hour", now.hour() + 1 - (now.hour() % 1));
    const nexts = R.map((x) => now.clone().add(x, "hour"), [1, 2, 3, 4]);
    const times = [now, ...nexts];
    if (error || !data) {
      road.forEach((p) => {
        const shouldBeRendered = getRoadFragment(p, 0, 10000);
        let feature = new Feature({
          geometry: new MultiLineString(
            changeBaseXyToPoint(shouldBeRendered, "line")
          ),
        });
        feature.setStyle(iconStyle("underAdjustment"));
        features.push(feature);
      });
    } else {
      icons.forEach((icon) => {
        const risks = R.uniq(
          R.filter(
            (r) => r,
            R.flatten(
              R.map((p) => {
                const forecast = R.find((f) => f.pointno === p.pointNo, data);
                if (!forecast) {
                  return null;
                }
                const records = R.map(
                  (t) =>
                    R.find(
                      (f) =>
                        f.forecast_date.hour === t.hour() &&
                        f.forecast_date.day === t.date() &&
                        f.forecast_date.month === t.month() + 1 &&
                        f.forecast_date.year === t.year(),
                      forecast.forecast_data
                    ),
                  times
                );
                return R.map(determineRisks, records);
              }, icon.points)
            )
          )
        );
        if (risks.length > 0) {
          const redRisks = risks.filter((r) => r.level === "red");
          const riskForIcon = redRisks.length > 0 ? redRisks[0] : risks[0];
          const feature = new Feature(new Point([icon.pointX, icon.pointY]));
          feature.set("points", icon.points);
          feature.setStyle(
            new Style({
              image: new Icon({
                crossOrigin: "anonymous",
                scale: 0.085,
                src: toRiskIcon(riskForIcon),
                opacity: 0.7,
              }),
            })
          );
          features.push(feature);
          icon.points.forEach((point) => {
            if (roadIdMap[point.roadName]) {
              roadIdMap[point.roadName].forEach((roadId) => {
                const roadParts = road.filter((p) => p.roadId === roadId);
                roadParts.forEach((p) => {
                  if (
                    Math.max(point.kpStart, p.kpStart) <
                    Math.min(point.kpEnd, p.kpEnd)
                  ) {
                    const roadFeature = new Feature({
                      geometry: new MultiLineString(
                        changeBaseXyToPoint(
                          getAllRoadFragment(p, point.kpStart, point.kpEnd),
                          "line"
                        )
                      ),
                    });
                    roadFeature.setStyle(
                      iconStyle(redRisks.length === 0 ? "yellow" : "red")
                    );
                    if (redRisks.length === 0) {
                      yellowRoadFeatures.push(roadFeature);
                    } else {
                      redRoadFeatures.push(roadFeature);
                    }
                  } else {
                    return null;
                  }
                });
              });
            }
          });
        } else {
          const feature = new Feature(new Point([icon.pointX, icon.pointY]));
          feature.set("points", icon.points);
          const dataAtPoint = R.find(
            R.propSatisfies(
              (x) => x === icon.points[0].pointNo.toString(),
              "pointno"
            ),
            data
          );
          if (!dataAtPoint) {
            // console.log("cannot find dataAtPoint", icon.points[0]);
            return;
          }
          const dataAtTime = R.find(
            (f) =>
              f.forecast_date.hour === now.hour() &&
              f.forecast_date.day === now.date() &&
              f.forecast_date.month === now.month() + 1 &&
              f.forecast_date.year === now.year(),
            dataAtPoint.forecast_data
          );
          if (!dataAtTime) {
            console.error("cannot find dataAtTime");
            return;
          }
          feature.setStyle(
            new Style({
              image: new Icon({
                crossOrigin: "anonymous",
                scale: 0.085,
                opacity: 0.7,
                src: `${
                  process.env.PUBLIC_URL
                }/weather_icons/${weatherCodeToName(dataAtTime.WX)}.png`,
              }),
            })
          );
          features.push(feature);
        }
      });
    }
    riskIconsLayer.setSource(new VectorSource({ features }));
    roadLayers[0].setSource(new VectorSource({ features: yellowRoadFeatures }));
    roadLayers[1].setSource(new VectorSource({ features: redRoadFeatures }));
  }, [
    icons,
    data,
    riskIconsLayer,
    roadLoading,
    road,
    roadLayers,
    error,
    loading,
    isSmartPhone,
  ]);

  return {
    riskIconsLayer,
    roadLayers: roadLayers,
    component: visible ? component : null,
    callback,
    setVisible,
  };
}
