import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { CM_CONSTANT } from "./const";

const data = {
  risk: ["red", "yellow", "underAdjustment"],
  camera: [
    "closed",
    "snowChain",
    "accident",
    "snowPlow",
    "antifreeze",
    "snowTires",
    "oneLane",
    "jam",
    "underAdjustment",
  ],
};

const riskData = [
  {
    image_red: "strongwind_red",
    image_yellow: "strongwind_yellow",
    content: "横風強風",
  },
  {
    image_red: "badview_red",
    image_yellow: "badview_yellow",
    content: "視界不良",
  },
  {
    image_red: "slipping_red",
    image_yellow: "slipping_yellow",
    content: "スリップ",
  },
];

export default function Legend({ mode }) {
  if (!["risk", "camera"].includes(mode)) return null;
  return (
    <Box
      m={["10px", "80px"]}
      fontSize="16px"
      fontFamily="Kosugi Maru"
      borderRadius="10px"
      zIndex={0}
      position={"absolute"}
      bottom="10%"
    >
      <Popover matchWidth returnFocusOnClose={false} autoFocus={false}>
        <PopoverTrigger m={"100px"}>
          <Button onMouseDown={(e) => e.preventDefault()} boxShadow="dark-lg">
            凡例
          </Button>
        </PopoverTrigger>
        <PopoverContent w="180px">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader fontWeight="bold">凡例</PopoverHeader>
          <PopoverBody>
            {data[mode].map((i) => (
              <Flex key={i} alignItems="center">
                <Box
                  fontSize="16px"
                  color={CM_CONSTANT.MAP.TRAFFIC_LAYER.KINDS[i].color}
                >
                  {i === "accident" ? "×" : "■"}
                </Box>
                <Box>{CM_CONSTANT.MAP.TRAFFIC_LAYER.KINDS[i].txt}</Box>
              </Flex>
            ))}
            {mode === "risk"
              ? riskData.map((i) => (
                  <Flex key={i.content} mt={"3px"} alignItems={"center"}>
                    <Image
                      src={`${process.env.PUBLIC_URL}/risk_icons/${i.image_yellow}.png`}
                      alt=""
                      boxSize="32px"
                    />
                    <Image
                      src={`${process.env.PUBLIC_URL}/risk_icons/${i.image_red}.png`}
                      alt=""
                      boxSize="32px"
                      mx="5px"
                    />
                    <Box>{i.content}</Box>
                  </Flex>
                ))
              : null}
            <Text mt={"10px"} fontSize={"10px"} fontWeight={"light"}>
              ※上記の情報は関東甲信越エリアで表示されます。
            </Text>
            <Text fontSize={"10px"} fontWeight={"light"}>
              以下の路線では表示されません。
            </Text>
            <Text fontSize={"10px"} fontWeight={"light"}>
              東名、新東名、中央道、北陸道、上信越道（一部）、長野道（一部）、圏央道（一部）、中部横断道、東富士五湖道路、小田原厚木道路、西湘バイパス
            </Text>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}
